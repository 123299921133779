import React, {useEffect, useState} from 'react';
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import {
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    Typography,
    CircularProgress,FormHelperText
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import DDImage from "../../../../component/DDImage";
import {useTranslation} from "react-i18next";
import {TextareaAutosize} from "@mui/material";
import {TextField} from "@mui/material";
import commonServices from "../../../../services/common-services";
import {useAppMutation} from "../../../../services";
import studentServices from "../../../../services/student-services";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import Span from "@jumbo/shared/Span";

const ProjectItemCard = ({item, setCourse}) => {
    console.log("Course data---------", item);

    const {
        mutate: submitRequest,
        isError,
        isLoading,
        isSuccess,
        data,
        error
    } = useAppMutation(studentServices.submitCourseRequest);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogRequest, setOpenDialogRequest] = React.useState(false);
    const {t} = useTranslation();

    const [description, setDescription] = useState("");
    const [errMsg, setErrMsg] = React.useState('');
    const [successMsg, setSuccessMsg] = React.useState('');
    const [validationError, setValidationError] = useState('');

    useEffect(() => {

        if (isSuccess) {
            setSuccessMsg(t('student-course.title.courseSuccess'))
            setDescription('');
        } else if (error && isError) {
            if (error.message === "Request Already in Queue" || error === "Request Already in Queue") {
                setErrMsg(t('student-course.title.courseQueue'));
            } else {
                setErrMsg(error.message || error);
            }
        }


    }, [isSuccess, isError,error])

    const handleChange = (event) => {
        setDescription(event.target.value);
        if (event.target.value.trim() !== "") {
            setValidationError(''); // Clear validation error when description is not empty
        }
    };

    const submitStep = async () => {
        if (description.trim() === "") {
            setValidationError(t('student-course.title.descriptionNotEmpty'));

            // Clear validation error after 3 seconds
            setTimeout(() => {
                setValidationError('');
            }, 3000);

            return; // Exit function if description is empty
        }
        setErrMsg('');
        setValidationError('');
        console.log("Course Id---->",item.courseId)
        console.log("Course Name---->",item.name)
        console.log("Description---->",description)

        submitRequest({
            courseId:item.courseId,
            title:item.name,
            description
        })
    };

    const handleEmailIconClick = () => {
        setOpenDialog(true);
    };


    return (
        <Card>
            <CardHeader
                avatar={
                    <Chip
                        color={"success"}
                        size={"small"}
                        label={t('student-course.title.course')}
                    />
                }
                action={
                    <IconButton aria-label="settings" onClick={handleEmailIconClick}>
                        <MoreHorizIcon/>
                    </IconButton>
                }
            ></CardHeader>
            <CardContent
                sx={{
                    pt: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Div
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                    }}
                >
                    {/*<Div sx={{display: 'flex', mb: 2}}>
                        <Avatar sx={{width: 72, height: 72}} src={item.images[0]}/>
                    </Div>*/}
                    <Div sx={{display: "flex", mb: 2}}>
                        {item.images?.length > 0 ? (
                            <DDImage
                                sx={{width: 72, height: 72}}
                                path={item.images[0]}
                                alt={item.firstName + " " + item.lastName}
                            />
                        ) : (
                            <Avatar
                                sx={{width: 72, height: 72}}
                                alt={item.firstName + " " + item.lastName}
                            />
                        )}
                    </Div>

                    <Typography variant={"h4"} mb={2}>
                        {item.name}
                    </Typography>
                    <Typography variant={"h6"} mb={0.5}>
                        {item.noOfBooking + " " + t('student-course.title.noOfBookings')}
                    </Typography>
                    <Typography variant={"h6"} mb={0.5}>
                        {item.noOfHours + " " + t('student-course.title.noOfhours')}
                    </Typography>
                    <Typography variant={"h6"} mb={0.5}>
                        {t('student-course.title.validity')+ " " + item.validity + " " + t('student-course.title.months')}
                    </Typography>
                    <Typography variant={"h6"} mb={2}>
                        {item.amount + " " +"€"}
                    </Typography>

                    {/*<Div sx={{
                        display: 'flex',
                        minWidth: 0,
                        flexDirection: 'column',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        width: '240px',
                        maxWidth: '100%',
                    }}
                    >
                        <LinearProgress
                            variant={"determinate"}
                            color={item?.status?.linear_color}
                            value={70}
                            sx={{
                                width: '85%',
                                borderRadius: 4,
                                height: 5,
                                mb: 1,
                                backgroundColor: '#E9EEEF'
                            }}
                        />
                        <Typography
                            variant={"body1"}
                            color={"text.secondary"}
                            mb={3}
                        >{`26/30 tasks ${item?.status?.label}`}
                        </Typography>
                    </Div>*/}
                    <Button variant={"contained"} size={"small"} onClick={() => {
                        setCourse(item)
                    }}>
                        {t('student-course.title.pay')}
                    </Button>
                    <Button variant={"contained"} size={"small"} sx={{mt: 1}} onClick={() => setOpenDialogRequest(true)}>
                        {t('student-course.title.request')}
                    </Button>
                </Div>
            </CardContent>
            <Div>
                <Dialog fullWidth open={openDialog}>
                    {/*<DialogTitle>{(t('common.resetPassword'))}</DialogTitle>*/}
                    <Div sx={{position: 'relative', textAlign: 'center'}}>
                        <DialogTitle>{(t('common.courseDetails'))}</DialogTitle>
                        <IconButton
                            onClick={() => setOpenDialog(false)}
                            sx={{position: 'absolute', top: '8px', right: '16px'}}
                        >
                            <img src="/images/icons/close.png" alt="Close" width="18"/>
                        </IconButton>
                    </Div>
                    <DialogContent
                        sx={{
                            overflowY: "scroll",
                            height: "auto",
                            "::-webkit-scrollbar": {
                                display: "none",
                            },
                            "-ms-overflow-style": "none", // IE and Edge
                            "scrollbar-width": "none", // Firefox
                        }}
                    >


                        <FormControl fullWidth noValidate autoComplete="off">
                            <Div>
                                <CardContent
                                    sx={{
                                        pt: 0,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Div
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Div sx={{display: "flex", mb: 2}}>
                                            {item.images?.length > 0 ? (
                                                <DDImage
                                                    sx={{width: 72, height: 72}}
                                                    path={item.images[0]}
                                                    alt={item.firstName + " " + item.lastName}
                                                />
                                            ) : (
                                                <Avatar
                                                    sx={{width: 72, height: 72}}
                                                    alt={item.firstName + " " + item.lastName}
                                                />
                                            )}
                                        </Div>

                                        <Typography variant={"h4"} mb={2}>
                                            {item.name}
                                        </Typography>


                                        <Typography
                                            variant="body1"
                                            mb={0.5}
                                            dangerouslySetInnerHTML={{
                                                __html: item.descriptionHTML,
                                            }}
                                        />

                                        <Typography variant={"h6"} mb={0.5}>
                                            {item.noOfBooking + " " + t('student-course.title.noOfBookings')}
                                        </Typography>
                                        <Typography variant={"h6"} mb={0.5}>
                                            {item.noOfHours + " " + t('student-course.title.noOfhours')}
                                        </Typography>
                                        <Typography variant={"h6"} mb={0.5}>
                                            {t('student-course.title.validity')+ " " + item.validity + " " + t('student-course.title.months')}
                                        </Typography>
                                        <Typography variant={"h6"} mb={2}>
                                            {item.amount + " " +"€"}
                                        </Typography>

                                        <Button
                                            variant={"contained"}
                                            size={"small"}
                                            sx={{mr: 1, width: "50%"}}
                                            onClick={() => setOpenDialog(false)}
                                        >
                                            {t('common.closeBtn')}
                                        </Button>
                                    </Div>
                                </CardContent>
                            </Div>
                        </FormControl>
                    </DialogContent>
                </Dialog>
            </Div>


            <Div>
                <Dialog fullWidth open={openDialogRequest}>
                    {/*<DialogTitle>{(t('common.resetPassword'))}</DialogTitle>*/}
                    <Div sx={{position: 'relative', textAlign: 'center'}}>
                        <DialogTitle>{t('student-course.title.request')}</DialogTitle>
                        <IconButton
                            onClick={() => {
                                setOpenDialogRequest(false);
                                setSuccessMsg('');
                                setDescription('');
                                setErrMsg('');
                                setValidationError('');
                            }}
                            sx={{position: 'absolute', top: '8px', right: '16px'}}
                        >
                            <img src="/images/icons/close.png" alt="Close" width="18"/>
                        </IconButton>
                    </Div>
                    <DialogContent
                        sx={{
                            overflowY: "scroll",
                            height: "auto",
                            "::-webkit-scrollbar": {
                                display: "none",
                            },
                            "-ms-overflow-style": "none", // IE and Edge
                            "scrollbar-width": "none", // Firefox
                        }}
                    >


                        <FormControl fullWidth noValidate autoComplete="off">
                            <Div>
                                <CardContent
                                    sx={{
                                        pt: 0,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Div
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            textAlign: "center",
                                            width:'100%'
                                        }}
                                    >
                                        <Div sx={{display: "flex", mb: 2}}>
                                            {item.images?.length > 0 ? (
                                                <DDImage
                                                    sx={{width: 72, height: 72}}
                                                    path={item.images[0]}
                                                    alt={item.firstName + " " + item.lastName}
                                                />
                                            ) : (
                                                <Avatar
                                                    sx={{width: 72, height: 72}}
                                                    alt={item.firstName + " " + item.lastName}
                                                />
                                            )}
                                        </Div>

                                        <Typography variant={"h4"} mb={2}>
                                            {item.name}
                                        </Typography>


                                        <TextField
                                            id="outlined-multiline-static"
                                            multiline
                                            rows={4}
                                            defaultValue="Default Value"
                                            label={t('student-course.title.descriptionLabel')}
                                            placeholder={t('student-course.title.descriptionText')}
                                            value={description}
                                            onChange={handleChange}
                                            sx={{width:'100%'}}
                                            required
                                            error={!!validationError}
                                        />
                                        {validationError && (
                                            <FormHelperText error>{validationError}</FormHelperText> // Show validation error message
                                        )}


                                        <Button
                                            variant={"contained"}
                                            size={"small"}
                                            sx={{ mr: 1, width: "50%", mt: 3, mb: 1 }}
                                            onClick={submitStep}
                                            disabled={isLoading} // Disable the button while loading
                                        >
                                            {isLoading ? (
                                                <CircularProgress size={18} sx={{ color: 'white' }} />
                                            ) : (
                                                t('student-course.title.submit')
                                            )}
                                        </Button>



                                        <Button
                                            variant={"contained"}
                                            size={"small"}
                                            sx={{mr: 1, width: "50%",mt:.5}}
                                            onClick={() => {
                                                setOpenDialogRequest(false);
                                                setSuccessMsg('');
                                                setDescription('');
                                                setErrMsg('');
                                                setValidationError('');
                                            }}
                                        >
                                            {t('common.closeBtn')}
                                        </Button>
                                    </Div>
                                    {successMsg &&
                                    <Typography variant={"body1"} sx={{mt:2}}>
                                        <Span sx={{color: 'success.main', ml: 1}}>
                                            <DoneAllIcon fontSize={"small"} sx={{verticalAlign: 'middle', mr: 1}}/>
                                            {successMsg}
                                        </Span>
                                    </Typography>
                                    }

                                    {errMsg &&
                                    <Typography variant={"body1"} sx={{mt:2}}>
                                        <Span sx={{color: 'error.main', ml: 1}}>
                                            <ErrorIcon fontSize={"small"} sx={{verticalAlign: 'middle', mr: 1}}/>
                                            {errMsg}
                                        </Span>
                                    </Typography>
                                    }
                                </CardContent>
                            </Div>
                        </FormControl>
                    </DialogContent>
                </Dialog>
            </Div>

        </Card>
    );
};

export default ProjectItemCard;
